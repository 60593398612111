<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-3 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->

    <!-- Alert Tidak Punya Akses Halaman -->
    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "Jabatan"
      </v-alert>
    </v-col>

    <!-- Alert Data Tersimpan -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      v-model="Alert"
      class="rounded-lg"
      icon="mdi-check-bold"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      Data Berhasil Disimpan
    </v-alert>

    <!-- Alert Data Berhasil Terubah -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      class="rounded-lg"
      icon="mdi-check-bold"
      v-model="AlertBerhasilTerubah"
      transition="slide-x-transition"
    >
      Data Berhasil Diubah
    </v-alert>

    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-delete"
      v-model="AlertDataTerhapus"
      transition="slide-x-transition"
    >
      Data Berhasil Dihapus
    </v-alert>

    <!-- ---------------------------------------------------- -->

    <v-container fluid v-show="this.valid == true">
      <v-card tile outlined class="elevation-3">
        <div class="pa-2">
          <v-toolbar
            flat
            outlined
            height="55"
            color="dark"
            v-resize="onResize"
          >
            <v-toolbar-title>
              Data Cuti Karyawan
            </v-toolbar-title>
            <v-divider inset vertical class="ml-2"></v-divider>
            <v-spacer></v-spacer>

            <!-- Menu Export Data -->
            <div class="text-center mx-2">
              <v-menu
                v-model="MenuExport"
                :close-on-content-click="false"
                :nudge-width="1"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- Btn Export Mode Biasa -->
                  <v-btn
                    @click="MenuExport = !MenuExport"
                    class="text-capitalize"
                    v-show="mobile == false"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    :disabled="loading == true"
                  >
                    <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                    <span class="subtitle-1">Export Data</span>
                    <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </template>

                <v-card outlined height="100" class="pa-2" v-show="MenuExport">
                  <!-- PDF -->
                  <div class="mb-2">
                    <v-btn
                      text
                      block
                      outlined
                      @click.native="PdfExport"
                      class="text-capitalize subtitle-1"
                    >
                      Pdf
                      <v-icon right>mdi-adobe-acrobat</v-icon>
                    </v-btn>
                  </div>

                  <!-- EXCEL -->
                  <div>
                    <v-btn
                      text
                      block
                      outlined                       
                      @click.native="excelExport"
                      class="text-capitalize subtitle-1"
                    >
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-menu>

              <!-- Btn Export Dialog Mode Mobile -->
              <v-dialog
                v-model="DialogPilihExport"
                max-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="mobile == true"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    text
                    fab
                    :disabled="loading == true"
                  >
                    <v-icon>mdi-table-arrow-right</v-icon>
                  </v-btn>
                </template>
                <v-card class="rounded-lg">
                  <v-toolbar dense>
                    <v-layout justify-center>
                      <v-toolbar-title>
                        Pilih Export File
                      </v-toolbar-title>
                    </v-layout>
                  </v-toolbar>
                  <v-card class="pa-2">
                    <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                      Pdf
                      <v-icon right>mdi-adobe-acrobat</v-icon>
                    </v-btn>

                    <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </v-card>
                </v-card>
              </v-dialog>
            </div>

            <!-- Dialog Tambah Cuti -->
            <v-dialog
              v-model="DialogTambahCuti"
              max-width="650"
              scrollable
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Tambah Mode Desktop -->
                <v-btn
                  class="text-capitalize mr-n2"
                  v-show="mobile == false && ValidCreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                  <span class="mr-n1 subtitle-1">Tambah</span>
                </v-btn>

                <!-- Btn Tambah Mode Mobile -->
                <v-btn 
                  class="mr-n2"
                  v-show="mobile == true && ValidCreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar flat height="64">
                  <v-toolbar-title class="title">
                    <span class="text-h5 mx-1">{{ formTitleCuti }}</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="KeluarDialogCuti"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                
                <v-card-text>
                  <div class="mr-n3 ml-n3 mb-n2 mt-3">
                    <v-sheet outlined class="rounded-lg pa-5">
                      <v-row> 
                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            label="No. Cuti"
                            color="dark"
                            v-model="editedItem.NoCuti"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            readonly
                            label="Karyawan"
                            color="dark"
                            v-model="editedItem.Nama"
                          >
                            <template v-slot:append-outer>
                              <v-dialog
                                v-model="DialogKdKaryawan"
                                max-width="1000px"
                                scrollable
                                persistent
                              >
                                <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                    dark
                                    x-small
                                    depressed
                                    height="25"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red darken-4"
                                  >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                  </v-btn>
                                </template>
                                <v-card class="rounded-lg">
                                  <v-toolbar flat height="64">
                                    <v-toolbar-title class="title">
                                      <span class="text-h5 mx-1">Pilih Karyawan</span>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      icon
                                      @click="DialogKdKaryawan = false"
                                    >
                                      <v-icon>mdi-window-close</v-icon>
                                    </v-btn>
                                  </v-toolbar>
                                  <v-divider></v-divider>
                                  <v-card-text>
                                    <div class="mr-n3 ml-n3 mb-n2 mt-3">                                          
                                      <v-card outlined class="elevation-3">
                                        <div class="pa-2">
                                          <ejs-grid
                                            ref='gridkaryawanview'
                                            height="184"
                                            gridLines='Both'
                                            id="kodekaryawanview"
                                            :dataSource="karyawan"
                                            :allowPaging="true"
                                            :allowSorting='true'
                                            :allowResizing='true'
                                            :toolbar='toolbarOptions'
                                            :recordDoubleClick="rowDblClickKdKaryawan"
                                            :pageSettings='pageSettings'
                                            :rowSelected="rowSelectedKdKaryawan" 
                                          >
                                            <e-columns>
                                              <e-column field='NRK' headerText='NRK' width=150></e-column>
                                              <e-column field='Nama' headerText='Nama' width=150></e-column>
                                            </e-columns>
                                          </ejs-grid>
                                        </div>
                                      </v-card>                                      
                                    </div>     
                                  </v-card-text>                                   
                                  <v-divider></v-divider>
                                  <v-card-actions>
                                    <span class="d-inline-block text-truncate">
                                      <span>Status : <span>{{StatusPilih}}</span></span>
                                    </span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      dark
                                      depressed
                                      color="red darken-4 mx-n2"
                                      class="text-capitalize"
                                      @click="SimpanKdKaryawan"
                                    >
                                      <v-icon left>mdi-check-bold</v-icon>
                                      <span>Simpan</span>
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-dialog
                            v-model="MenuTglPengajuan"
                            :return-value.sync="date"
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.TanggalPengajuan"
                                label="Tanggal Pengajuan"
                                prepend-icon="mdi-calendar"
                                color="dark"
                                dense
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.TanggalPengajuan"
                              @input="MenuTglPengajuan = false"
                              year-icon="mdi-calendar-blank"
                              color="red darken-4"
                              class="rounded-lg"
                              locale="id"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-dialog
                            v-model="MenuTglMulai"
                            :return-value.sync="date"
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.TanggalMulai"
                                label="Tanggal Mulai"
                                prepend-icon="mdi-calendar"
                                color="dark"
                                readonly
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.TanggalMulai"
                              @input="MenuTglMulai = false"
                              year-icon="mdi-calendar-blank"
                              color="red darken-4"
                              class="rounded-lg"
                              locale="id"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-dialog
                            v-model="MenuTglSelesai"
                            :return-value.sync="date"
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.TanggalSelesai"
                                label="Tanggal Selesai"
                                prepend-icon="mdi-calendar"
                                color="dark"
                                readonly
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.TanggalSelesai"
                              @input="MenuTglSelesai = false"
                              year-icon="mdi-calendar-blank"
                              color="red darken-4"
                              class="rounded-lg"
                              locale="id"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            label="Jam Mulai"
                            color="dark"
                            v-model="editedItem.JamMulai"
                            type="time"
                            hint="Wajib Diisi." 
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            label="Jam Selesai"
                            color="dark"
                            v-model="editedItem.JamSelesai"
                            type="time"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            label="Jenis Cuti"
                            color="dark"
                            v-model="editedItem.JenisCuti"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            label="Alasan Cuti"
                            color="dark"
                            v-model="editedItem.AlasanCuti"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6">
                          <v-text-field
                            dense
                            label="Status Cuti"
                            color="dark"
                            v-model="editedItem.StatusCuti"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <span class="d-inline-block text-truncate">
                    Status : <span>{{Status}}</span>
                  </span>
                  <v-spacer></v-spacer>

                  <v-btn
                    dark
                    depressed
                    color="red darken-4 mr-n2"
                    class="text-capitalize"
                    @click="Simpan"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    <span class="mr-1">Simpan</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-show="loading == true">
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                height="6"
                color="red darken-4"
              ></v-progress-linear>
            </div>
          </v-toolbar>

          <div>
            <ejs-grid
              ref='gridcuti'
              style="height: 100%;"
              height="43vh"
              gridLines='Both'
              id="cutiview"
              :dataSource="DataCuti"
              :allowPaging="true"
              :allowSorting='true'
              :allowResizing='true'
              :allowGrouping='true'
              :toolbar='toolbarOptions'
              :commandClick="commandClick"
              :pageSettings='pageSettings'
              :allowExcelExport='true'
              :allowPdfExport='true'
            >
              <e-columns>
                <e-column headerText="Aksi" textAlign='Center' width="145" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                  <div class="btn-group">
                    <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                      <i class="fa fa-pencil"></i>
                    </v-btn>
                    <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                      <i class="fa fa-trash"></i>
                    </v-btn>
                  </div>
                </e-column>
                <e-column field='NoCuti' headerText='No Cuti' textAlign='Left' width=170></e-column>
                <e-column field='karyawan.Nama' headerText='Nama' width=300></e-column>
                <e-column field='StatusCuti' headerText='Status Cuti' width=150></e-column>
                <e-column field='KodeKaryawan' headerText='Kode Karyawan' :visible="false" width=300></e-column>
                <e-column field='TanggalPengajuan' headerText='Tanggal Pengajuan' width=150></e-column>
                <e-column field='TanggalMulai' headerText='Tanggal Mulai' width=150></e-column>
                <e-column field='TanggalSelesai' headerText='Tanggal Selesai' width=150></e-column>
                <e-column field='JamMulai' headerText='Jam Mulai' width=150></e-column> 
                <e-column field='JamSelesai' headerText='Jam Selesai' width=150></e-column>
                <e-column field='JenisCuti' headerText='Jenis Cuti' width=150></e-column>
                <e-column field='AlasanCuti' headerText='Alasan Cuti' width=150></e-column>
                <e-column field='DiBuatOleh' headerText='DibuatOleh' textAlign='Left' width=150></e-column>
                <e-column field='DiBuatTgl' headerText='DibuatTgl' textAlign='Left' width=150></e-column>
                <e-column field='DiUbahOleh' headerText='DiubahOleh' textAlign='Left' width=150></e-column>
                <e-column field='DiUbahTgl' headerText='DiubahTgl' textAlign='Left' width=150></e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>
      </v-card>
    </v-container>

    <v-dialog
      v-model="DialogLihatSuratCuti"
      persistent
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card :class="$vuetify.theme.dark ? 'grey darken-4' : ''">
        <v-card-actions style="height: 64px;">
          <v-toolbar-title class="text-h5">
            Surat Permohonan Izin Cuti
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text outlined class="text-capitalize" depressed @click="CetakSurat">Cetak</v-btn>
          <v-btn text outlined class="text-capitalize" depressed @click="DialogLihatSuratCuti = false">Keluar</v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text :class="$vuetify.theme.dark ? 'dark' : 'grey lighten-3'">
          <v-container class="fill-height" fluid>
            <v-row>
              <v-sheet outlined width="21cm" height="auto" class="ma-8 mx-auto elevation-15">
                <div id="cetakan" style="padding: 35px; width: 21cm; height: auto; margin: auto; font-family: 'Calibri', sans-serif;">
                  <!-- Kepala Surat Start -->
                  <table style="border: 1px solid; font-family: 'Calibri', sans-serif;">
                    <tr style="border: 1px solid;">
                      <td style="border: 1px solid; width: 30%; text-align: center;">
                        <img style="width: 188px; padding-top: 5px;" src="../assets/TA_Logo.png"/>
                      </td>
                      <td colspan="2" style="border: 1px solid; font-size: 15px; text-align: center;">
                        <div>Jl. Bung Tomo No. 49 RT. 11 Telp/Fax . 0541 260521, Samarinda Seberang,</div>
                        <div>Email : hrga@trinityauto.id</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="border: 1px solid; font-size: 18px; width: 30%; padding: 10px;" rowspan="4">SURAT PERMOHONAN IZIN CUTI</th>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">No. Dokumen</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">FORMULIR HRD.12</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">Tanggal Dibuat</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">01 Maret 2016</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">Revisi</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">1</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">Halaman</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">1-1</td>
                    </tr>
                  </table>
                  <!-- Kepala Surat End -->

                  <!-- Isi Surat Start -->
                  <div style="font-family: 'Calibri', sans-serif; font-size: 14px; margin-left: 10px; margin-right: 10px;">
                    <table style="margin-top: 10px;">
                      <tr>
                        <td style="text-align: left;" colspan="2" v-text="'No : '+editedItem.NoCuti"></td>
                        <td style="text-align: right;" colspan="2" v-text="'Samarinda, '+DibuatTgl"></td>
                      </tr>
                      <tr>
                        <td colspan="4">Sesuai kepentingannya, dengan ini diberikan izin cuti (<strong style="font-style: italic;">-TAHUNAN, -BERSALIN </strong>)* kepada karyawan/(ti) :</td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Nama</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="editedItem.karyawan.Nama"></td>

                        <td rowspan="8" style="width: 20%; text-align: right;">
                          <div style="width: 120px; height: 120px; margin: auto;">
                            <figure class="qrcode" v-show="editedItem.StatusCuti == 'Disetujui'">
                              <qrcode
                                style="width: 120px; height: 120px; margin-bottom: -6px;"
                                :value="'http://192.168.1.92:8080/SuratCuti/'+editedItem.NoCuti"
                                tag="svg"
                                :options="{
                                  errorCorrectionLevel: 'Q',
                                  width: 120,
                                }"
                              ></qrcode>
                              <!-- <img
                                style="width: 50px; height: 50px;"
                                class="qrcode__image"
                                src="../assets/LogoTrinity.png"
                                alt="Barcode"
                              /> -->
                            </figure>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">NRK</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="editedItem.karyawan.NRK"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Awal Kerja</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="editedItem.karyawan.TglMasuk"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Jabatan</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="editedItem.jabatan.NamaJabatan"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%; vertical-align: top;">Keperluan Alasan</td>
                        <td style="width: 1%; vertical-align: top;">:</td>
                        <td style="width: 58%;" v-text="editedItem.AlasanCuti"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Mulai Tanggal</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;">
                          <label v-text="editedItem.TanggalMulai"></label>
                          <label style="margin-right: 10px; margin-left: 10px;">s/d</label>
                          <label v-text="editedItem.TanggalSelesai"></label>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 22%;">Jumlah Cuti Diambil</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;">
                          <label v-text="editedItem.JumlahCuti"></label>
                          <label style="margin-right: 10px; margin-left: 10px;">Sisa Hak Cuti</label>
                          <label v-text="editedItem.karyawan.Cuti"></label>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 22%;">Kembali Bekerja Tanggal</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="editedItem.TanggalKerjaKembali"></td>
                      </tr>
                      <tr>
                        <td colspan="4">Demikian surat permohonan izin cuti ini kami berikan agar dapat dipergunakan sebagai mana mestinya.</td>
                      </tr>
                    </table>

                    <table style="text-align: center; margin-top: 10px;">
                      <tr>
                        <td style="width: 25%;" colspan="1">Dibuat Oleh :</td>
                        <td colspan="2">Diperiksa Oleh :</td>
                        <td style="width: 25%;" colspan="1">Disetujui Oleh :</td>
                      </tr>
                      <tr>
                        <th style="width: 25%;" v-text="editedItem.karyawan.Nama"></th>
                        <th style="width: 25%;">Rudy Tjan</th>
                        <th style="width: 25%;">Alfian Malik</th>
                        <th style="width: 25%;">Herdaru Purwianto</th>
                      </tr>
                      <tr>
                        <td style="width: 25%;">Pemohon</td>
                        <td style="width: 25%;">Head Dept</td>
                        <td style="width: 25%;">HR</td>
                        <td style="width: 25%;">Opr Div Head</td>
                      </tr>
                    </table> 
                  </div>
                  <!-- Isi Surat End -->
                </div>
              </v-sheet>
            </v-row>
          </v-container>
          <!-- <page size="A4" layout="landscape"></page> -->
        </v-card-text>
      </v-card>
    </v-dialog>
      
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      DibuatTgl:'',
      DialogLihatSuratCuti: false,
      date: new Date().toISOString().substr(0, 10),
      MenuTglPengajuan: false,
      MenuTglMulai: false,
      MenuTglSelesai: false,
      DialogKdKaryawan:false,
      mobile:null,
      valid:null,
      Permission:[],
      ValidCreate:false,
      SomeStorage:[],
      passbaru:null,
      KodeKaryawan:null,
      textAktif:true,
      umenu:[],
      verifpassbaru:null,
      loading:true,
      Kode:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      karyawan:[],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      DialogTambahCuti: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        NoCuti: "",
        Nama:"",
        KodeKaryawan: "",
        TanggalPengajuan: "",
        TanggalMulai: "",
        TanggalSelesai: "",
        JamMulai: "",
        JamSelesai: "",
        JenisCuti: "",
        AlasanCuti: "",
        StatusCuti: "",
        karyawan:"",
        jabatan:"",
      },
      editedItem: {
        NoCuti: "",
        Nama:"",
        KodeKaryawan: "",
        TanggalPengajuan: "",
        TanggalMulai: "",
        TanggalSelesai: "",
        JamMulai: "",
        JamSelesai: "",
        JenisCuti: "",
        AlasanCuti: "",
        StatusCuti: "",
        karyawan:"",
        jabatan:"",
      },
      toolbarOptions: ['Search'],
      DataCuti: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    // await Promise.all([this.saya()])
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
      this.getkaryawan()
      this.autotanggal()
      this.commands.push({buttonOption: { cssClass: "e-flat Lihat", iconCss: "e-icons mata", content:' '} })
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
  },
  
  created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleCuti() {
      return this.editedIndex === -1 ? 'Tambah Data Cuti' : 'Ubah Data Cuti'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
    StatusPilih () {
      return this.editedIndex === -1 ? 'Pilih' : 'Pilih'
    },
  },
  
  watch: {
  
      // let a = this.DataCuti.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahCuti (val) {
      val || this.KeluarDialogCuti()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    CetakSurat(){
      let contain = document.getElementById('cetakan')
      // var cln = contain.cloneNode(true);
      // contain.style.height = 'auto'
      // const prtHtml = contain.innerHTML;
      const prtHtml = contain.innerHTML;
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        
        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var is_chrome = Boolean(WinPrint.chrome);
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
        WinPrint.document.close();
        if (is_chrome) {
        WinPrint.onload = function() { // wait until all resources loaded 
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();  // change window to mywindow
            WinPrint.close();// change window to mywindow
            };
        }
        else {
            WinPrint.document.close(); // necessary for IE >= 10
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();
            WinPrint.close();
        }
    },
    print(){
      // Get HTML to print from element
        const prtHtml = document.getElementById('print').innerHTML;

        // Get all stylesheets HTML
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }

  
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataCuti.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
          // this.editedItem.UserMenu = a[0].UserMenu
        }else{
          //do nothing
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    Simpan(){
      if(this.formTitleCuti === "Tambah Data Cuti"){
        this.Toast('Data Disimpan')
          api.post('/cuti?token='+this.token,{
            NoCuti: this.editedItem.NoCuti,
            KodeKaryawan: this.editedItem.KodeKaryawan,
            TanggalPengajuan: this.editedItem.TanggalPengajuan,
            TanggalMulai: this.editedItem.TanggalMulai,
            TanggalSelesai: this.editedItem.TanggalSelesai,
            JamMulai: this.editedItem.JamMulai,
            JamSelesai: this.editedItem.JamSelesai,
            JenisCuti: this.editedItem.JenisCuti,
            AlasanCuti: this.editedItem.AlasanCuti,
            StatusCuti: this.editedItem.StatusCuti,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                // this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitleCuti === "Ubah Data Cuti"){
        this.Toast('Data Diubah')
         api.put("/cuti/"+this.editedItem.NoCuti+'/?token='+this.token,{
            NoCuti: this.editedItem.NoCuti,
            KodeKaryawan: this.editedItem.KodeKaryawan,
            TanggalPengajuan: this.editedItem.TanggalPengajuan,
            TanggalMulai: this.editedItem.TanggalMulai,
            TanggalSelesai: this.editedItem.TanggalSelesai,
            JamMulai: this.editedItem.JamMulai,
            JamSelesai: this.editedItem.JamSelesai,
            JenisCuti: this.editedItem.JenisCuti,
            AlasanCuti: this.editedItem.AlasanCuti,
            StatusCuti: this.editedItem.StatusCuti,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogCuti()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);

      }else if (args.target.classList.contains("Lihat")) {
        console.log('console lihat',args)
        let DibuatTgl = args.rowData.DibuatTgl
        DibuatTgl = DibuatTgl.substr(0, 10)
        this.DibuatTgl = this.FormatTanggal(DibuatTgl)
        this.editedItem = args.rowData
        this.DialogLihatSuratCuti = true
        // console.log(args)
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            .delete("/cuti/"+args.rowData.KodeKaryawan+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              // this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.KodeKaryawan = args.rowData.KodeKaryawan
        let data = args
            this.editedIndex = 1;
            // console.log(data)
            this.editedItem = data.rowData
            this.DialogTambahCuti = true

          // if (args.rowData.Aktif == 0) {
          //   this.dataTemp = args.rowData
          //   this.dialogAktifData = true
          //   }else{
          //       let data = args;
          //       this.editedIndex = 1;
          //       this.Kode = data.rowData.Kode
          //       this.editedItem.Nama = data.rowData.Nama
          //       this.editedItem.Password = data.rowData.Password
          //       this.editedItem.UserMenu = data.rowData.UserMenu
          //       // this.dialogWO = true;
          //   }
      }
    },

    getkaryawan(){
     if (this.valid == true) {
        api.get('/karyawan?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					// console.log(res);
          this.karyawan = res.data
          this.loading = false
				},
				err => {
					console.log(err);
				}
			)
      }
    },

    SimpanKdKaryawan(){
      this.editedItem.KodeKaryawan = this.SomeStorage.KodeKaryawan
      this.editedItem.Nama = this.SomeStorage.Nama
      this.DialogKdKaryawan = false
    },

    rowDblClickKdKaryawan(args){
      this.editedItem.KodeKaryawan = args.rowData.KodeKaryawan
      this.editedItem.Nama = args.rowData.Nama
      this.DialogKdKaryawan = false
    },

    rowSelectedKdKaryawan(args){
      this.SomeStorage = []
      this.SomeStorage.Nama = args.data.Nama
      this.SomeStorage.KodeKaryawan = args.data.KodeKaryawan
    },

    PdfExport(){
      let data = document.getElementById('cutiview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportCuti.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridcuti.columns[0].visible = false;
      // this.$refs.gridcuti.columns[1].visible = true;
      this.$refs.gridcuti.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridcuti.excelExport();
      // console.log(this.$refs.gridcuti)
      // let data = document.getElementById('cutiview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogCuti () {
      this.DialogTambahCuti = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
        this.autotanggal()
      })
    },

    autotanggal() {
      this.editedItem.TanggalPengajuan = this.date
      // this.editedItem.tanggal_pengiriman = this.date
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      api.get('/cuti?token='+this.token,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
					// console.log(res);
          if (res) {
            this.DataCuti = res.data
          this.loading = false
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },

    FormatTanggal(x){
      var todayTime = new Date(x);
      var bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      var month = bulan[todayTime .getMonth()];
      var day = todayTime .getDate();
      var year = todayTime .getFullYear();
      return day + " " + month + " " + year;  
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style scoped>
  table{
    border-collapse:collapse;
    width: 100%;
  }
  .mata{ 
      content:'\e345'; 
  }
  .custom-file-input {
    border: 1px solid #ccc;
    display: inline-block solid #ccc;
    padding: 6px 6px 6px;
    cursor: pointer;
  }

  .e-grid .e-rowcell.customcss{
    background-color: #d6d6d6;
  }
  .e-grid .e-headercell.customcss{
    background-color: #ecedee;
  }

  .qrcode {
  display: inline-block;
  /* font-size: 0;
  margin-bottom: 0; */
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 19%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  width: 10%;
}
</style>


{% endraw %}